///<reference path="./index.d.ts"/>

import "./draggable";
import "./expand-collapse";
import getQueryStringValue from "./get-query-string-value";
import "./jquery.center";
import "./message-counter";
import "./modals";
import "./usage-tracking";
import {simplify_inquiry_request,  inquiry_request_no_maintain } from "./usage-tracking";

window.GetQueryStringValue = window.GetQueryStringValue || getQueryStringValue;
window.inquiry_request_no_maintain = window.inquiry_request_no_maintain || inquiry_request_no_maintain;
window.simplify_inquiry_request = window.simplify_inquiry_request || simplify_inquiry_request;
