function simplify_inquiry_maintain(id) {
    var xmlhttp;
    var send;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            window.setTimeout(function () {
                simplify_inquiry_maintain(xmlhttp.responseText)
            }, 60000);
        }
    }

    xmlhttp.open("POST", ("https://d1zf5faa59afyn.cloudfront.net/api/observer60.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&id=" + id);
}

export function simplify_inquiry_request(user_first_name: string, user_last_name: string, user_email: string, user_company: string, user_subscription_type: string, request_top_level: string, request_mid_level: string, request_low_level: string, user_id, user_parent_id: string, tracked_url:string = null) {
    var xmlhttp;
    var send;
    tracked_url = tracked_url || window.location.href;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new
            XMLHttpRequest();
    } else {
        xmlhttp = new
            ActiveXObject("Microsoft.XMLHTTP");
    }

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            window.setTimeout(function () {
                simplify_inquiry_maintain(xmlhttp.responseText)
            }, 60000);
        }
    }

    xmlhttp.open("POST", ("https://d1zf5faa59afyn.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf=" + user_first_name + "&ul=" + user_last_name + "&ue=" + user_email + "&uc=" + user_company + "&us=" + user_subscription_type + "&r1=" + request_top_level +
        "&r2=" + request_mid_level + "&r3=" + request_low_level + "&uccid=" + user_id + "&uccpid=" + user_parent_id + "&xu=" + encodeURIComponent(tracked_url) +
        "&xw=" + screen.width + "&xh=" + screen.height + "&xn=" + navigator.userAgent + "&xc=" + navigator.cookieEnabled + "&xl=" + navigator.language +
        "&xp=" + navigator.platform + "&xx=10");
}

export function inquiry_request_no_maintain(user_first_name: string, user_last_name: string, user_email: string, user_company: string,
    user_subscription_type: string, request_top_level: string, request_mid_level: string, request_low_level: string, user_id, user_parent_id: string,
    tracked_url: string = null, searchWithNoResults: boolean = false) {
    var xmlhttp;
    var send;
    tracked_url = tracked_url || window.location.href;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new
            XMLHttpRequest();
    } else {
        xmlhttp = new
            ActiveXObject("Microsoft.XMLHTTP");
    }
    let noresults = (searchWithNoResults === true) ? "no_results" : "";

    xmlhttp.open("POST", ("https://d1zf5faa59afyn.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf=" + user_first_name + "&ul=" + user_last_name + "&ue=" + user_email + "&uc=" + user_company + "&us=" + user_subscription_type + "&r1=" + request_top_level +
        "&r2=" + request_mid_level + "&r3=" + request_low_level + "&uccid=" + user_id + "&uccpid=" + user_parent_id + "&xu=" + encodeURIComponent(tracked_url) +
        "&xw=" + screen.width + "&xh=" + screen.height + "&xn=" + navigator.userAgent + "&xc=" + navigator.cookieEnabled + "&xl=" + navigator.language +
        "&xp=" + navigator.platform + "&c4=" + noresults + "&xx=10");
}
