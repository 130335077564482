/**
 * Get value from query string.
 */
export default function (key: string): string {
    const k = encodeURIComponent(key).replace(/[.+*]/g, "\\$&");
    const r = new RegExp("^(?:.*[&\\?]" + k + "(?:\\=([^&]*))?)?.*$", "i");

    const s = window.location.search.replace(r, "$1");
    return decodeURIComponent(s);
}
