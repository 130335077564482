///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>


function getBulletinCount() {
    return $.ajax({
        url: "/MVC/Bulletins/GetBulletinCount",
        method: "GET",
        dataType: "json",
        data: $.param({ activeOnly: true }) // only get ACTIVE bulletins for the Code Tracker
    });
}
function getMessageCount() {
    return $.ajax({ url: "/MVC/Messages/GetMessageCount", method: "GET", dataType: "json" });
}

$.when(
    getMessageCount(),
    getBulletinCount()
).done(function (messagesArr1, bulletinsArr2) {
    // REFERENCE: http://api.jquery.com/jQuery.when/
    //    The parameters are Arrays that contains values from the AJAX done callback.
    //    example: [data, statusText, jqXHR]
    var msgCount = parseInt(messagesArr1[0], 10);
    msgCount = isNaN(msgCount) ? 0 : msgCount;
    if (msgCount > 0) {
        $("a.my-messages-counter").append(' <span class="badge">' + msgCount + '</span>');
    }

    var bulletinCount = parseInt(bulletinsArr2[0], 10);
    bulletinCount = isNaN(bulletinCount) ? 0 : bulletinCount;
    if (bulletinCount > 0) {
        $("a.my-bulletins-counter").append(' <span class="badge">' + bulletinCount + '</span>');
    }

    var totalMsgCount = msgCount + bulletinCount;
    if (totalMsgCount > 0) {
        $("li.dropdown a.dropdown-toggle span.my-account").append(' <span class="badge">' + totalMsgCount + '</span>');
    }
});
