///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>

/**
 * jQuery plugin method to center a DOM node on the screen.
 * 
 * Usage:
 * $(".element_class_name").center();
 */

$.fn.center = function() {
    this.css("position", "absolute");
    this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
    this.css("left", ($(window).width() - this.width()) / 2 + $(window).scrollLeft() + "px");
    return this;
};
