///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>


$("#noteSearch").keyup(function () {
    var noteSearchText = $("#noteSearch").val();
    if (noteSearchText != "") {
        $(".notes-expand").each(function () {
            var isExpanded = $(this).attr('aria-expanded');
            if (isExpanded == null || isExpanded == 'false') {

                $(this).trigger('click');
            }
        });
    }
    else
    {
        $(".notes-expand").each(function () {
            if (! $(this).hasClass("collapsed")) {

                $(this).trigger('click');
            }
        });
    }
       
});
