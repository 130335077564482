///<reference path="../../../node_modules/@types/jquery/index.d.ts"/>

/**
    * This event handler is designed to work on <span class="icons-hhcc icon-expand"> tags
    * to toggle between .icon-expand and .icon-collapse,
    * and relies on Twitter Bootstrap"s Collapsible JavaScript.
    */
$('span.icons-hhcc[data-toggle="collapse"]').click(function () {
    $(this).toggleClass("icon-expand icon-collapse");
});

$(".related-toggle").on("click", function () {
    var src = $(this);
    if (src.text() === "View Related OASIS items") {
        src.text("Hide Related OASIS items");
    }
    else {
        src.text("View Related OASIS items");
    }
});

$(".related-toggleI10").on("click", function () {
    var src = $(this);
    if (src.text().trim() === "View Related Codes") {
        src.text("Hide Related Codes");
    }
    else {
        src.text("View Related Codes");
    }
});

/**
* Toggle "active" class on "OASIS Search Result for..." 
* page's "Collapse All"/"Expand All" btns.
*/
$(".btn-collapse-hide-all").on("click", function () {
    $(".btn-collapse-show-all").removeClass("active");
    $(this).addClass('active');
});

$(".btn-collapse-show-all").on("click", function () {
    $(".btn-collapse-hide-all").removeClass("active");
    $(this).addClass('active');
});
